<template>
  <div class="container-fluid">
    <div class="music-album-wrapper">
      <div class="row">
        <!-- Album Info -->
        <div class="col-md-12 col-lg-12 col-xl-4 col-12">
          <div class="album-info">
            <img class="img-fluid album-cover"
                 alt="album-cover"
                 :src="album.album_thumbnail"
            />
            <h4 class="mt-2 mb-0"> {{ album.album_name }} </h4>
            <p class="total-tracks">
              {{ album.no_of_tracks }}
              <span v-if="album.no_of_tracks > 1">Songs</span>
              <span v-else>Song</span>
            </p>
          </div>
        </div>

        <!-- Music Player -->
        <div class="col-md-12 col-lg-12 col-xl-8 col-12">
          <div class="music-player">
            <!-- Player -->
            <div class="player">
              <audio id="player"
                     preload="metadata"
                     controls
                     :autoplay="player.autoplay"
                     :loop="player.repeat"
                     :src="activeSong.url"
              ></audio>

              <div class="row">
                <!-- Left -->
                <div class="col-md-3 col-12">
                  <div class="left">
                    <transition name="fade">
                      <p class="song-title"> {{ activeSong.filename }} </p>
                    </transition>
                  </div>
                </div>
                
                <!-- Center -->
                <div class="col-md-6 col-12">
                  <div class="center text-center">
                    <i class="fas fa-redo repeat"
                       :class="player.repeat ? 'active' : ''"
                       :title="player.repeat ? 'Repeat Off' : 'Repeat On'"
                       @click="toggleRepeat"></i>
                    <i class="fa fa-step-backward previous"
                       title="Previous"
                       :class="queTrackNo === 0? 'disabled' : ''"
                       @click="previousSong"></i>
                    <i class="fa fa-play play"
                       title="Play"
                       v-if="player.status !== 'playing'"
                       @click="playPauseSong('play')"></i>
                    <i class="fa fa-pause pause active"
                       title="Pause"
                       v-if="player.status === 'playing'"
                       @click="playPauseSong('pause')"></i>
                    <i class="fa fa-step-forward next"
                       title="Next"
                       @click="nextSong"></i>
                    <i class="fas fa-random shuffle"
                       :class="player.shuffle ? 'active': ''"
                       :title="player.shuffle ? 'Shuffle Off' : 'Shuffle On'"
                       @click="toggleShuffle"></i>

                    <!-- Seeker -->
                    <div class="slide-container">
                      <p class="time small text-left mb-0">
                        {{ player.currTimeTxt }}
                      </p>
                      <input class="seeker-slider"
                             id="seeker"
                             type="range"
                             v-model="player.currTime"
                             :max="player.durTime"
                             :min="0"
                             :title="player.currTimeTxt" />
                      <p class="time small text-right mb-0">
                        {{ player.durTimeTxt }}
                      </p>
                      <p class="mobile-time small text-center mb-0">
                        {{ player.currTimeTxt }} / {{ player.durTimeTxt }}
                      </p>
                    </div>
                  </div>
                </div>

                <!-- Right -->
                <div class="col-md-3 col-12">
                  <div class="right">
                    <i class="fas fa-volume-up unmute"
                       title="Mute"
                       v-if="player.volume !== '0'"
                       @click="toggleMute"></i>
                    <i class="fas fa-volume-off mute"
                       title="Unmute"
                       v-else
                       @click="toggleMute"></i>
                    <input class="volume-slider"
                           min="0"
                           max="1"
                           step="0.1"
                           type="range"
                           v-model="player.volume" />
                  </div>
                </div>
              </div>
            </div>

            <!-- Playlist -->
            <ul class="playlist">
              <li class="song"
                  v-for="(song, index) in songs"
                  :class="activeIndex === index ? 'active' : ''"
                  :key="index"
                  @click="setActiveSong(index)">
                <p class="small title mb-0"> {{ song.filename }} </p>
                <p class="small length mb-0">{{ song.length | formatTime }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import _ from 'lodash';
  import FilterMixin from '@/mixins/FilterMixin';

  export default {
    name   : 'MusicAlbum',
    mixins : [
      FilterMixin,
    ],
    data() {
      return {
        queTrackNo  : null,
        queTracks   : [],
        activeSong  : {},
        activeIndex : null,
        exceeded    : false,

        player : {
          autoplay    : false,
          currTime    : 0,
          durTime     : 0,
          currTimeTxt : '00:00',
          durTimeTxt  : '00:00',
          loaded      : false,
          mute        : false,
          repeat      : false,
          shuffle     : false,
          status      : null,
          volume      : 0.5,
          oldVolume   : 0,
        },
        totalPlayed : 0,
        start       : 0,
        end         : null,
        oldCurrTime : null,
      }
    },
    computed : {
      ...mapGetters({
        album : 'media/album',
        songs : 'media/albumSongs',
      }),
      currentTime() {
        return this.player.currTime;
      },
      volume() {
        return this.player.volume;
      },
      loaded() {
        return this.player.loaded;
      },
    },
    watch : {
      '$route.params.albumId' : {
        handler : function() {
          this.getAlbum(this.$route.params.albumId);
          this.getAlbumSongs(this.$route.params.albumId);
        },
        deep      : true,
        immediate : true,
      },
      songs(value) {
        if (value !== [])
          this.setQueTracks();
      },
      queTrackNo(value) {
        this.activeIndex = this.queTracks[value];
        this.activeSong = this.songs[this.activeIndex];
      },
      activeSong(value) {
        this.totalPlayed = 0;
        this.start = 0;
        this.end = null;
        this.oldCurrTime = null;
        
        if (value.length) {
          this.player.currTime = 0;
          this.player.durTime = value.length;
          this.player.durTimeTxt = this.formatTime(this.player.durTime);
        } else {
          setTimeout(() => {
            this.player.durTime = (value.length) ? value.length :
              document.getElementById('player').duration;
            this.songs[this.activeIndex].length = this.player.durTime;
            this.player.durTimeTxt = this.formatTime(this.player.durTime);
          }, 1000);
        }
      },
      currentTime(newValue, oldValue) {
        this.oldCurrTime = oldValue;
        let diff = 0;
        if (newValue > oldValue)
          diff = newValue - oldValue;
        else
          diff = oldValue - newValue;

        if (diff > 1)
          document.getElementById('player').currentTime = newValue;
      },
      volume(value) {
        document.getElementById('player').volume = value;
      },
      loaded(value) {
        if (value)
          this.player.autoplay = true;
      },
    },
    methods : {
      ...mapActions({
        'getAlbum'      : 'media/getAlbum',
        'getAlbumSongs' : 'media/getAlbumSongs',
      }),

      /**
       * Set Active Song
       * @param id
       */
      setActiveSong(id) {
        if (!this.player.shuffle)
          this.queTrackNo = id;
        else {
          this.queTrackNo = _.findIndex(this.queTracks, (el) => {
            return el === id;
          });
        }
      },

      /**
       * Set Que Tracks Order
       */
      setQueTracks() {
        this.queTracks = [];

        // this.queTrackNo = null;

        for (let i = 0; i < this.songs.length; i++)
          this.queTracks.push(i);
        
        if (this.player.shuffle)
          this.queTracks = _.shuffle(this.queTracks);
      },

      /**
       * Toggle Repeat Song
       */
      toggleRepeat() {
        this.player.repeat = !this.player.repeat;
      },

      /**
       * Toggle Shuffle Playlist
       */
      toggleShuffle() {
        this.player.shuffle = !this.player.shuffle;
        this.queTrackNo = 0;
        this.setQueTracks();
      },

      /**
       * Toggle Volume Mute
       */
      toggleMute() {
        this.player.mute = !this.player.mute;

        if (this.player.mute) {
          document.getElementById('player').muted = true;
          this.player.oldVolume = this.player.volume;
          this.player.volume = '0';
        } else {
          document.getElementById('player').muted = false;
          this.player.volume = this.player.oldVolume;
        }
      },

      /**
       * Play/Pause Song
       */
      playPauseSong(action) {
        const player = document.getElementById('player');
        if (action === 'play') {
          if (this.queTrackNo === null)
            this.queTrackNo = 0;
          this.startStream(player)
          //console.log("PLAY TRIGGERED....")
        } else{
          this.stopStream();
          player.pause();
          //console.log("PAUSE TRIGGERED....")
        }
      },

      /**
       * Go to Previous Song
       */
      previousSong() {
        this.player.autoplay = true;
        if (this.queTrackNo !== 0) {
          this.queTrackNo--;
          this.player.status = 'previous';
        }
      },

      /**
       * Go to Next Song
       */
      nextSong() {
        this.player.autoplay = true;
        if (this.queTrackNo < (this.queTracks.length - 1)) {
          this.queTrackNo++;
          this.player.status = 'next';
        } else {
          // Back to First Track
          this.queTrackNo = 0;
        }
      },

      /**
       * Format Time
       * @param value
       */
      formatTime(value) {
        let result = '';
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value - (hours * 3600)) / 60);
        let seconds = value - (hours * 3600) - (minutes * 60);

        // round seconds & exclude remainder
        seconds = parseInt(Math.round(seconds * 100) / 100);

        if (hours > 0) {
          result = (hours < 10 ? '0' + hours : hours) +
            ':' + (minutes < 10 ? '0' + minutes : minutes) +
            ':' + (seconds < 10 ? '0' + seconds : seconds);
        } else {
          result = (minutes < 10 ? '0' + minutes : minutes) + ':' +
            (seconds < 10 ? '0' + seconds : seconds);
        }

        return result;
      },

      startStream(player){
        this.$http.post('api/access/startStream').then((ret) => {
          if(ret.data.code == '0002'){
            this.exceeded = true;
            player.pause();
            // eslint-disable-next-line no-alert
            alert('Number of concurrent media streaming for this acount has ' +
              'been exceeded.');
          } else
            player.play();
        }
        );
      },
      stopStream(){
        this.$http.post('api/access/stopStream');
      },
      
      /**
       * Get Total Played
       */
      getTotalPlayed() {
        let diff = 0;
        if (this.end)
          diff = this.end - this.start;
        
        this.totalPlayed = this.totalPlayed + diff;
      },
    },
    mounted() {
      this.$analytics.fbq.event('PageView', {
        'page'     : 'music-categories',
        'platform' : 'website',
      });

      /**
       * Audio Player Listeners
       */
      const player = document.getElementById('player');

      player.addEventListener('timeupdate', () => {
        this.$nextTick(() => {
          if (this.player.status === 'playing') {
            this.player.currTime = player.currentTime;
            this.player.currTimeTxt = this.formatTime(this.player.currTime);
          }
        });
      });

      player.addEventListener('seeking', () => {
        this.player.status = 'seeking';
        this.end = this.oldCurrTime;
        this.getTotalPlayed();
      });
      
      player.addEventListener('playing', () => {
        this.$analytics.fbq.event('ContentView', {
          'page'        : 'music-player',
          'platform'    : 'website',
          'music_title' : this.activeSong.filename,
          'music_id'    : this.activeSong.id,
        });
        this.player.status = 'playing';
        this.start = player.currentTime;
        this.end = null;
      });
      
      player.addEventListener('pause', () => {
        this.player.status = 'paused';
        this.end = player.currentTime;
        this.getTotalPlayed();
      });
      
      player.addEventListener('ended', async () => {
        // console.log("ENDED TRIGGERED....")
        this.end = player.duration;
        if (!this.player.repeat) {
          if (this.totalPlayed >= 60) {
            // Earn Points if music is played 60 seconds or more
            await this.$http.post('api/user/level', {
              id    : this.$store.getters['user/user'].user_id,
              event : 'LISTENED_MUSIC',
            });
          }
          
          this.$nextTick(() => {
            this.nextSong();
          });
        } else
          this.$http.post('api/access/stopStream');
        
      });
      
      player.addEventListener('loadeddata', () => {
        this.$analytics.fbq.event('ContentView', {
          'page'        : 'music-albums',
          'platform'    : 'website',
          'album_title' : this.$store.getters['media/album'].album_name,
          'album_id'    : this.$store.getters['media/album'].mobile_album_id,
        });
        this.player.loaded = true;
      });

      this.player.autoplay = false; // disable autoplay on album load
    },
    created() {
      window.addEventListener('beforeunload', (e) => {
        this.stopStream();
        delete e['returnValue'];
      });
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/user/music-album";
</style>